import { Controller } from '@hotwired/stimulus';
import { triggerTurboStream } from '../utils/trigger_turbo_stream';

export default class extends Controller {
  static targets = ['label'];
  static values = { name: String };
  private nameValue!: string;
  private labelTarget!: HTMLElement;

  async onParameterChange(event: Event) {
    event.preventDefault();
    const element = event.target! as HTMLElement;
    const value = element.getAttribute('data-value');
    await triggerTurboStream({
      src: this.prepareUrl(value),
      history: 'advance',
      forceReplace: true,
    });

    this.labelTarget.innerHTML = element.innerHTML!;
  }

  private prepareUrl(value: string | null) {
    const url = new URL(document.location.href);
    value
      ? url.searchParams.set(this.nameValue, value)
      : url.searchParams.delete(this.nameValue);

    return url;
  }
}
